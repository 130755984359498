<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      v-model="show"
      max-width="500px"
      @click:outside="init()"
      @keydown="$event.key === 'Escape' ? init() : ''"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
          :class="type === 0 || type === 1?'justify-space-between':''"
          >
          <span class="text-h5" v-if="type === 0">그룹생성</span>
          <span class="text-h5" v-else-if="type === 1 || type === 2">그룹수정</span>
          <span class="text-h5" v-else-if="type === 3">그룹설정</span>
          <div class="text-box" v-if="type === 0 || type === 1">
            <v-text-field
              class="mt-2"
              label="그룹이름"
              outlined
              dense
              :rules="rules"
              v-model="formName"
            ></v-text-field>
          </div>
          <div class="select-users" v-if="type === 2">
            <v-chip
              v-for="(item, index) in selectUsers"
              small
              :key="index"
              color="accent"
              class="mr-1"
            >{{ item.name }}({{ item.id }})</v-chip>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="msg-wrap mb-2 pa-3" v-if="type === 1">
            <v-icon small color="error" class="icon">mdi-alert-circle-outline</v-icon>
            <p>
              이동할 그룹을 선택해주세요.<br/>
              수정완료시 선택한 그룹의 하위로 이동합니다.
            </p>
          </div>
          <div class="msg-wrap mb-2 pa-3" v-else-if="type === 0">
            <v-icon small color="error" class="icon">mdi-alert-circle-outline</v-icon>
            <p>
              상위 그룹을 선택해주세요.<br/>
              선택한 그룹의 하위에 생성됩니다.
            </p>
          </div>
          <div class="msg-wrap mb-2 pa-3 error" v-if="type === 1 && errorMsg">
            <p>
              현재그룹의 하위로 이동할 수 없습니다.
            </p>
          </div>
          <div class="treeview-wrap">
            <div
              class="top-group"
              :class="activeDup.length === 0?'active primary--text':''"
              @click="activeDup = []">
              {{ companyInfo.name }}
            </div>
            <v-treeview
              :items="groups"
              hoverable
              activatable
              :active.sync="activeDup"
              return-object
              expand-icon="mdi-chevron-down"
              item-children="groups"
              item-key="no"
              dense
              :open.sync="openDup"
            >
            </v-treeview>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            :disabled="(type === 0 || type === 1)
              && (formName === null || formName === '' || rules[0] !== true || errorMsg)"
            @click="applyBtnClickFunc"
          >
            {{ type === 0?'생성':(type === 3?'설정':'수정') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import * as TreeModel from 'tree-model';
import comm from '@/util/comm';

export default {
  name: 'DlgUserAuth',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    func: {
      type: Function,
    },
    active: {
      type: Array,
    },
    open: {
      type: Array,
    },
    thisGroup: {
      type: Object,
    },
    selectUsers: {
      type: Array,
    },
  },
  data: () => ({
    comm,
    errorMsg: false,
    formName: null,
    rules: [true],
    activeDup: [],
    openDup: [],
    tree: new TreeModel({
      childrenPropertyName: 'groups',
      modelComparatorFn: null,
    }),
  }),
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.$emit('update:show', false);
      this.activeDup = JSON.parse(JSON.stringify(this.active));
      this.openDup = JSON.parse(JSON.stringify(this.open));
      this.formName = null;
      this.errorMsg = false;
    },
    applyBtnClickFunc() {
      const parentNo = this.activeDup.length === 0 ? null : this.activeDup[0].no;
      if (this.type === 1) {
        const originPathName = this.comm.getGroupFullPath(this.groups, this.thisGroup.no).trim();
        const newPathName = `${this.comm.getGroupFullPath(this.groups, parentNo)} ${this.formName}`;
        const func = () => {
          this.func(this.thisGroup.no, parentNo, this.formName);
          this.confirm({ show: false });
          this.init();
        };
        this.confirm({
          show: true,
          func,
          msg: `수정전: ${originPathName}<br/>수정후: ${newPathName}<br/><br/>위 내용대로 변경됩니다. 수정할까요?<br/><br/><span class="error--text text-body-2">※그룹의 리더는 그대로 유지되며,<br>모든 하위그룹의 매출이력과 고객을 열람할 수 있습니다.</span>`,
          btnText: '수정완료',
          color: 'primary',
        });
      } else if (this.type === 0) {
        this.func(parentNo, this.formName);
        this.init();
      } else if (this.type === 2) {
        const usersArr = [];
        this.selectUsers.forEach((r) => {
          usersArr.push({
            id: r.id,
            groupNo: parentNo,
          });
        });
        const func = () => {
          this.func(usersArr, '그룹이 수정되었습니다.');
          this.confirm({ show: false });
          this.init();
        };
        this.confirm({
          show: true,
          func,
          msg: '그룹 변경시 해당 직원의 모든 리더설정이 해제 됩니다.<br/><br/>정말 변경할까요?',
          btnText: '수정완료',
          color: 'primary',
        });
      } else if (this.type === 3) {
        this.func(
          parentNo,
          this.comm.getGroupFullPath(this.groups, parentNo),
          this.activeDup,
          this.openDup,
        );
        this.init();
      }
    },
  },

  watch: {
    thisGroup: {
      handler() {
        if (this.type === 1) {
          this.formName = this.thisGroup.name;
          const root = this.tree.parse({ groups: this.groups });
          const curruntGroup = root.first((r) => r.model.no === this.thisGroup.no);
          if (curruntGroup.getPath().length === 2) {
            this.activeDup = [];
          } else {
            this.activeDup = [curruntGroup.getPath()[curruntGroup.getPath().length - 2].model];
          }
        }
      },
    },
    active: {
      immediate: true,
      handler() {
        this.activeDup = JSON.parse(JSON.stringify(this.active));
      },
    },
    open: {
      immediate: true,
      handler() {
        this.openDup = JSON.parse(JSON.stringify(this.open));
      },
    },
    formName: {
      handler(val) {
        if (val !== null && (val.length > 20 || val.match(/\s/g))) {
          this.rules = ['공백을 제외한 20자까지만 입력가능합니다.'];
        } else {
          this.rules = [true];
        }
      },
    },
    activeDup: {
      handler() {
        if (this.type === 1 && this.show === true) {
          this.errorMsg = false;
          const root = this.tree.parse({ groups: this.groups });
          const curruntGroup = root.first((r) => r.model.no === this.thisGroup.no);
          let includeCheck = true;
          curruntGroup.walk((node) => {
            if (this.activeDup.length > 0 && (this.activeDup[0].no === node.model.no)) {
              includeCheck = false;
            }
          });
          if (includeCheck === false) {
            this.errorMsg = true;
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.select-users {
  margin-top:10px;
  padding-bottom:6px;
  padding-left: 6px;
  border-radius: 4px;
  border:1px solid #ececec;
  width: 100%;
  background-color: #fff;
}
.text-box {
  margin-bottom:-12px;
  margin-left: 10px;
}
.treeview-wrap {
  border-radius: 4px;
  border: 1px solid #ddd;
}
.top-group {
  padding:10px;
  display: flex;
  align-items: center;
  transition: .3s ease-in-out;
  color: #000000de;
  &.active {
    background-color: #E1E7F6 !important;
  }
  &:hover {
    background-color: #f6f6f6;
  }
}
.msg-wrap {
  display: flex;
  align-items: flex-start;
  background-color: #f2f2f2;
  border-radius: 4px;
  &.error {
    p {
      color:#fff;
    }
  }
  p {
    margin:0;
    color: #333;
  }
  .icon {
    margin-top:3px;
    margin-right: 3px;
  }
}
</style>
